import { ProductCatalogFindSimilarProductsReponseV1 } from '../../domainTypes/productCatalog';
import { callFirebaseFunction } from '../../services/firebaseFunctions';

export const productCatalogFindSimilarProducts = (
  urls: string[],
  opts: {
    alwaysIncludeSimilarMatches?: boolean;
  } = {}
): Promise<{
  time: number;
  data: ProductCatalogFindSimilarProductsReponseV1[];
}> => {
  return callFirebaseFunction<{
    time: number;
    data: ProductCatalogFindSimilarProductsReponseV1[];
  }>('productCatalog-findSimilarProducts', {
    urls,
    opts: {
      alwaysIncludeSimilarMatches: opts.alwaysIncludeSimilarMatches ?? true
    }
  });
};
