import React from 'react';
import {
  Archive as IconProductCatalog,
  Box as IconBuilds,
  Cpu as IconServices,
  Flag as IconFlag,
  Grid as IconSpaces,
  Link as IconLinkCheck,
  List as IconPerformance,
  Play as IconQueue,
  Repeat as IconAffiliates,
  Search as IconSearch,
  Send as IconAcquisition,
  Settings as IconSettings,
  Sliders as IconOps,
  TrendingUp as IconMonitoring,
  Truck,
  Users as IconUsers
} from 'react-feather';
import { RouteComponentProps } from 'react-router-dom';
import { PageAcquisitionPartnerOverview } from './features/Acquisition/pages/PartnerOverview';
import { PageAcquisitionPotentialUsersList } from './features/Acquisition/pages/PotentialUsersList';
import { PageAcquisitionScanDetails } from './features/Acquisition/pages/ScanDetails';
import { PageAcquisitionScanStats } from './features/Acquisition/pages/ScanStats';
import { PageAcquisitionTagOverview } from './features/Acquisition/pages/TagOverview';
import { PageAcquisitionTrialMonitor } from './features/Acquisition/pages/TrialMonitor';
import { PageFeatureFlags } from './features/Admin/FeatureFlags';
import { PageAdminRoles } from './features/AdminRoles';
import { PageAffiliateOverview } from './features/Affiliates/pages/Overview';
import { PageAffilimateConfig } from './features/AffilimateConfig';
import { PageAnalyticsV2 } from './features/AnalyticsV2';
import { PageChromeExtensionOps } from './features/ChromeExtension/pages/Ops';
import { PageClickhouseSeedClicks } from './features/ClickhouseSeedClicks';
import { PageClickhouseSeedLinks } from './features/ClickhouseSeedLinks';
import { PageClickhouseSeedPageViewsV2 } from './features/ClickhouseSeedPageViewsV2';
import { PageClickhouseSeedSales } from './features/ClickhouseSeedSales';
import { PageDenormalizations } from './features/Denomalizations';
import { PageBuildDetails } from './features/Deployments/pages/BuildDetails';
import { PageDeploymentBuilds } from './features/Deployments/pages/Builds';
import { PageServiceDetails } from './features/Deployments/pages/ServiceDetails';
import { PageDeploymentServices } from './features/Deployments/pages/Services';
import { PageEventsList } from './features/Events/pages/List';
import { PageFirestoreInspector } from './features/FirestoreInspector';
import { FIRESTORE_INSPECTOR_COLLECTIONS } from './features/FirestoreInspector/collections';
import { PageJobs } from './features/Jobs';
import { PageLinkCheckAppCheckDetails } from './features/LinkCheck/pages/AppCheckDetails';
import { PageLinkCheckAppChecks } from './features/LinkCheck/pages/AppChecks';
import { PageLinkCheckDebug } from './features/LinkCheck/pages/Debug';
import { PageLinkCheckDebugDetails } from './features/LinkCheck/pages/DebugDetails';
import { PageLinkCheckPackages } from './features/LinkCheck/pages/Packages';
import { PageLinkCheckStandaloneChecks } from './features/LinkCheck/pages/StandaloneChecks';
import { PageLinkCheckWorkers } from './features/LinkCheck/pages/Workers';
import { PageLinkShortener } from './features/LinkShortener';
import { PageLogs } from './features/Logs';
import { PageMaintenanceSettings } from './features/MaintenanceSettings';
import { PageMigrations } from './features/Migrations';
import { PageMonitoringOverview } from './features/Monitoring/pages/Overview';
import { PageNotifications } from './features/Notifications';
import { PageQueueStats } from './features/PageQueue/pages/Stats';
import { PagePerformanceReports } from './features/Performance/pages/Reports';
import { PagePerformanceRetrigger } from './features/Performance/pages/Retrigger';
import { PagePostgres } from './features/Postgres';
import { PageProductCatalogEmbeddingProcesses } from './features/ProductCatalog/pages/EmbeddingProcesses';
import { PageProductCatalogFindSimilarProducts } from './features/ProductCatalog/pages/FindSimilarProducts';
import { PageProductCatalogFullTextSearch } from './features/ProductCatalog/pages/FullTextSearch';
import { PageProductCatalogProductMatchByUrl } from './features/ProductCatalog/pages/ProductMatchByUrl';
import { PageProductCatalogProductSearchViaEmbedding } from './features/ProductCatalog/pages/ProductSearchViaEmbedding';
import { PageProductCatalogRetrievalProcesses } from './features/ProductCatalog/pages/RetrievalProcesses';
import { PagePubSub } from './features/PubSub';
import { PageRealtimeProcesses } from './features/RealtimeProcesses';
import { PageRealtimeSalesProcesses } from './features/RealtimeSalesProcesses';
import { PageScanLookup } from './features/Scans/pages/Lookup';
import { PageSpeedRestrictions } from './features/Scans/pages/SpeedRestrictions';
import { PageScanTest } from './features/Scans/pages/Test';
import { PageSchedulesOps } from './features/Schedules/pages/Ops';
import { PageSpaceDetails } from './features/Spaces/pages/Details';
import { PageSpacesList } from './features/Spaces/pages/List';
import { PageUserList } from './features/Users/pages/List';

export interface ITab {
  key: string;
  path: string;
  label: string;
  render: (props: RouteComponentProps<any>) => React.ReactNode;
}

export interface IPage {
  path: string;
  title: string;
  tabs: ITab[];
}

export interface ILoosePage<T = any> {
  path: string;
  render: (props: RouteComponentProps<T>) => JSX.Element;
}

export const PAGES: IPage[] = [
  {
    path: '/spaces',
    title: 'Spaces',
    tabs: [
      {
        key: 'space',
        path: 'spaces',
        label: 'Find spaces',
        render: () => <PageSpacesList />
      }
    ]
  },
  {
    path: '/users',
    title: 'Users',
    tabs: [
      {
        key: 'user',
        path: 'users',
        label: 'Find users',
        render: () => <PageUserList />
      }
    ]
  },
  {
    path: '/linkCheck',
    title: 'Link Check',
    tabs: [
      {
        key: 'appChecks',
        path: 'app-checks',
        label: 'App Checks',
        render: () => <PageLinkCheckAppChecks />
      },
      {
        key: 'standaloneChecks',
        path: 'standalone-checks',
        label: 'Standalone Checks',
        render: () => <PageLinkCheckStandaloneChecks />
      },
      {
        key: 'workers',
        path: 'workers',
        label: 'Workers',
        render: () => <PageLinkCheckWorkers />
      },
      {
        key: 'packages',
        path: 'packages',
        label: 'Packages',
        render: () => <PageLinkCheckPackages />
      },
      {
        key: 'debug',
        path: 'debug',
        label: 'Debug',
        render: () => <PageLinkCheckDebug />
      }
    ]
  },

  {
    path: '/productCatalog',
    title: 'Product Catalog',
    tabs: [
      {
        key: 'productCatalogFullTextSearch',
        path: 'full-text-search',
        label: 'Full Text Search',
        render: () => <PageProductCatalogFullTextSearch />
      },
      {
        key: 'productCatalogProductMatchByUrl',
        path: 'product-match-by-url',
        label: 'Product Match by URL',
        render: () => <PageProductCatalogProductMatchByUrl />
      },
      {
        key: 'productCatalogProductSearchViaEmbedding',
        path: 'product-search-via-embedding',
        label: 'Product Search via Embedding',
        render: () => <PageProductCatalogProductSearchViaEmbedding />
      },
      {
        key: 'productCatalogFindSimilarProducts',
        path: 'product-find-similar-products',
        label: 'Find Similar Products',
        render: () => <PageProductCatalogFindSimilarProducts />
      },
      {
        key: 'productCatalogRetrievalProcesses',
        path: 'retrieval-processes',
        label: 'Retrieval Processes',
        render: () => <PageProductCatalogRetrievalProcesses />
      },
      {
        key: 'productCatalogEmbeddingProcesses',
        path: 'embedding-processes',
        label: 'Embedding Processes',
        render: () => <PageProductCatalogEmbeddingProcesses />
      }
    ]
  },
  {
    path: '/acquisition',
    title: 'Acquisition',
    tabs: [
      {
        key: 'potentialUsers',
        path: 'potential-users',
        label: 'Potential Users',
        render: () => <PageAcquisitionPotentialUsersList />
      },
      {
        key: 'partnerOverview',
        path: 'partner-overview',
        label: 'Partner Overview',
        render: () => <PageAcquisitionPartnerOverview />
      },
      {
        key: 'tagOverview',
        path: 'tag-overview',
        label: 'Tag Overview',
        render: () => <PageAcquisitionTagOverview />
      },
      {
        key: 'scanStats',
        path: 'scan-stats',
        label: 'Scan Stats',
        render: () => <PageAcquisitionScanStats />
      },
      {
        key: 'trialMonitor',
        path: 'trial-monitor',
        label: 'Trial Monitor',
        render: () => <PageAcquisitionTrialMonitor />
      }
    ]
  },
  {
    path: '/scans',
    title: 'Scans',
    tabs: [
      {
        key: 'scanLookup',
        path: 'scans',
        label: 'Lookup scans',
        render: () => <PageScanLookup />
      },
      {
        key: 'scanTest',
        path: 'scan-test',
        label: 'Test Single Page Scan',
        render: () => <PageScanTest />
      },
      {
        key: 'scanSpeedRestrictions',
        path: 'scan-speed-restrictions',
        label: 'Speed restrictions',
        render: () => <PageSpeedRestrictions />
      }
    ]
  },
  {
    path: '/page-queue',
    title: 'Page Queue',
    tabs: [
      {
        key: 'pageQueueStats',
        path: 'stats',
        label: 'Stats',
        render: () => <PageQueueStats />
      }
    ]
  },
  {
    path: '/etl',
    title: 'ETL',
    tabs: [
      {
        key: 'realtime',
        path: 'realtime',
        label: 'Realtime PV processes',
        render: () => <PageRealtimeProcesses />
      },
      {
        key: 'realtime_sales',
        path: 'realtime_sales',
        label: 'Realtime Sales Processes',
        render: () => <PageRealtimeSalesProcesses />
      },
      {
        key: 'seed_pv_v2',
        path: 'seed_pv_v2',
        label: 'Seed PV V2',
        render: () => <PageClickhouseSeedPageViewsV2 />
      },
      {
        key: 'seed_s',
        path: 'seed_s',
        label: 'Seed Sales',
        render: () => <PageClickhouseSeedSales />
      },
      {
        key: 'seed_c',
        path: 'seed_c',
        label: 'Seed Clicks',
        render: () => <PageClickhouseSeedClicks />
      },

      {
        key: 'seed_l',
        path: 'seed_l',
        label: 'Seed Links',
        render: () => <PageClickhouseSeedLinks />
      }
    ]
  },
  {
    path: '/ops',
    title: 'Operations',
    tabs: [
      {
        key: 'postgres',
        path: 'postgres',
        label: 'Postgres',
        render: () => <PagePostgres />
      },
      {
        key: 'firestoreInspector',
        path: 'firestore-inspector',
        label: 'Firestore Inspector',
        render: () => (
          <PageFirestoreInspector
            collections={FIRESTORE_INSPECTOR_COLLECTIONS}
          />
        )
      },
      {
        key: 'pubSub',
        path: 'pub-sub',
        label: 'PubSub',
        render: () => <PagePubSub />
      },
      {
        key: 'logs',
        path: 'logs',
        label: 'Logs',
        render: () => <PageLogs />
      },
      {
        key: 'linkShortener',
        path: 'link-shortener',
        label: 'Link Shortener',
        render: () => <PageLinkShortener />
      },
      {
        key: 'analytics_v2',
        path: 'analytics_v2',
        label: 'Analytics V2',
        render: () => <PageAnalyticsV2 />
      },
      {
        key: 'extension',
        path: 'extension',
        label: 'Browser Extension',
        render: () => <PageChromeExtensionOps />
      },
      {
        key: 'migrations',
        path: 'migrations',
        label: 'Migrations',
        render: () => <PageMigrations />
      },
      {
        key: 'denormalizations',
        path: 'denormalizations',
        label: 'Denormalizations',
        render: () => <PageDenormalizations />
      },
      {
        key: 'notifications',
        path: 'notifications',
        label: 'Notifications',
        render: () => <PageNotifications />
      },
      {
        key: 'events',
        path: 'events',
        label: 'Events',
        render: () => <PageEventsList />
      },
      {
        key: 'schedules',
        path: 'schedules',
        label: 'Schedules',
        render: () => <PageSchedulesOps />
      }
    ]
  },
  {
    path: '/deployments/services',
    title: 'Deployments',
    tabs: [
      {
        key: 'services',
        path: 'overview',
        label: 'Services',
        render: () => <PageDeploymentServices />
      }
    ]
  },
  {
    path: '/deployments/builds',
    title: 'Deployments',
    tabs: [
      {
        key: 'builds',
        path: 'overview',
        label: 'Builds',
        render: () => <PageDeploymentBuilds />
      }
    ]
  },
  {
    path: '/features-flags',
    title: 'Admin',
    tabs: [
      {
        key: 'featureFlags',
        path: 'feature-flags',
        label: 'Feature Flags',
        render: () => <PageFeatureFlags />
      }
    ]
  },
  {
    path: '/config',
    title: 'Admin',
    tabs: [
      {
        key: 'config',
        path: 'config',
        label: 'Affilimate Config',
        render: () => <PageAffilimateConfig />
      },
      {
        key: 'maintenance',
        path: 'maintenance',
        label: 'Maintenance Settings',
        render: () => <PageMaintenanceSettings />
      }
    ]
  },
  {
    path: '/roles',
    title: 'Admin',
    tabs: [
      {
        key: 'roles',
        path: 'roles',
        label: 'Roles',
        render: () => <PageAdminRoles />
      }
    ]
  },
  {
    path: '/monitoring',
    title: 'Monitoring',
    tabs: [
      {
        key: 'monitoringOverview',
        path: 'overview',
        label: 'Overview',
        render: () => <PageMonitoringOverview />
      }
    ]
  },
  {
    path: '/performance',
    title: 'Reporting',
    tabs: [
      {
        key: 'performanceReports',
        path: 'reports',
        label: 'Reports',
        render: () => <PagePerformanceReports />
      },
      {
        key: 'performanceRetrigger',
        path: 'retrigger',
        label: 'Retrigger',
        render: () => <PagePerformanceRetrigger />
      }
    ]
  },
  {
    path: '/jobs',
    title: 'Jobs',
    tabs: [
      {
        key: 'jobs',
        path: 'all',
        label: 'All',
        render: () => <PageJobs />
      }
    ]
  },
  {
    path: '/affiliates',
    title: 'Affiliates',
    tabs: [
      {
        key: 'affiliatesOverview',
        path: 'affiliates',
        label: 'Recent referrals',
        render: () => <PageAffiliateOverview />
      }
    ]
  }
];

export const LOOSE_PAGES: ILoosePage[] = [
  {
    path: '/spaces/:spaceId',
    render: (props) => <PageSpaceDetails spaceId={props.match.params.spaceId} />
  } as ILoosePage<{ spaceId: string }>,
  {
    path: '/deployments/builds/:buildId',
    render: (p) => <PageBuildDetails buildId={p.match.params.buildId} />
  } as ILoosePage<{ buildId: string }>,
  {
    path: '/deployments/services/:env/:name',
    render: (p) => (
      <PageServiceDetails env={p.match.params.env} name={p.match.params.name} />
    )
  } as ILoosePage<{ env: string; name: string }>,
  {
    path: '/acquisition/scan/:scanId',
    render: (p) => <PageAcquisitionScanDetails scanId={p.match.params.scanId} />
  } as ILoosePage<{ scanId: string }>,
  {
    path: '/linkCheck/details/:checkId',
    render: (p) => (
      <PageLinkCheckAppCheckDetails checkId={p.match.params.checkId} />
    )
  } as ILoosePage<{ checkId: string }>,
  {
    path: '/linkCheck/debug/:file',
    render: (p) => <PageLinkCheckDebugDetails file={p.match.params.file} />
  } as ILoosePage<{ file: string }>
];

export interface ICategory {
  id: string;
  route: string;
  icon: JSX.Element;
  disabled?: boolean;
}

export interface ICategoryGroup {
  id: string;
  children: ICategory[];
}

export const NAV: ICategoryGroup[] = [
  {
    id: 'Users',
    children: [
      {
        id: 'Spaces',
        icon: <IconSpaces size={20} />,
        route: '/spaces'
      },
      {
        id: 'Users',
        icon: <IconUsers size={20} />,
        route: '/users'
      },
      {
        id: 'Affiliates',
        icon: <IconAffiliates size={20} />,
        route: '/affiliates'
      }
    ]
  },
  {
    id: 'System',
    children: [
      { id: 'Scans', icon: <IconSearch size={20} />, route: '/scans' },
      { id: 'Page Queue', icon: <IconQueue size={20} />, route: '/page-queue' },
      {
        id: 'Monitoring',
        icon: <IconMonitoring size={20} />,
        route: '/monitoring'
      },
      {
        id: 'Reporting',
        icon: <IconPerformance size={20} />,
        route: '/performance'
      },
      {
        id: 'Jobs',
        icon: <IconPerformance size={20} />,
        route: '/jobs'
      },
      {
        id: 'Link Checker',
        icon: <IconLinkCheck size={20} />,
        route: '/linkCheck'
      },

      {
        id: 'Product Catalog',
        icon: <IconProductCatalog size={20} />,
        route: '/productCatalog'
      }
    ]
  },
  {
    id: 'Tools',
    children: [
      { id: 'ETL', icon: <Truck size={20} />, route: '/etl' },
      { id: 'Ops', icon: <IconOps size={20} />, route: '/ops' },
      {
        id: 'Acquisition',
        icon: <IconAcquisition size={20} />,
        route: '/acquisition'
      }
    ]
  },
  {
    id: 'Admin',
    children: [
      {
        id: 'Feature Flags',
        icon: <IconFlag size={20} />,
        route: '/feature-flags'
      },
      { id: 'Config', icon: <IconSettings size={20} />, route: '/config' },
      { id: 'Roles', icon: <IconUsers size={20} />, route: '/roles' }
    ]
  },
  {
    id: 'Deployments',
    children: [
      {
        id: 'Services',
        icon: <IconServices size={20} />,
        route: '/deployments/services'
      },
      {
        id: 'Builds',
        icon: <IconBuilds size={20} />,
        route: '/deployments/builds'
      }
    ]
  }
];
