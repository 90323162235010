import { Button, Card, CardContent } from '@material-ui/core';
import firebase from 'firebase/app';
import { flatten, groupBy, maxBy, pick, sortBy, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import shortid from 'shortid';
import urlParse from 'url-parse';
import { ButtonWithPromise } from '../../../components/ButtonWithPromise';
import { isLockedOut } from '../../../domainTypes/billing';
import { Doc } from '../../../domainTypes/document';
import {
  IPageMetadata,
  IPageRevision,
  IPageScreenshot
} from '../../../domainTypes/page';
import { IReport, ITrackedSale } from '../../../domainTypes/performance';
import { SPACE_ID } from '../../../domainTypes/potentialUser';
import { IProductDestination } from '../../../domainTypes/product';
import { IReportingPreviewParams } from '../../../domainTypes/reporting';
import { Schedule } from '../../../domainTypes/schedule';
import { ISecret } from '../../../domainTypes/secret';
import { ISpace, ISpaceOnboardingItem } from '../../../domainTypes/space';
import { API_REPORT_HANDLERS } from '../../../features/PerformanceNew/services/handlers';
import { toReportDoc } from '../../../features/PerformanceNew/services/report';
import {
  refreshSaleTimestamps,
  toTrackedSaleDoc
} from '../../../features/PerformanceNew/services/sale';
import { CanvasBar } from '../../../layout/Canvas';
import { Page } from '../../../layout/Page';
import { Section } from '../../../layout/Section';
import { toAutoLabelConfigDoc } from '../../../services/autoLabelConfig';
import { decompressType } from '../../../services/compression';
import {
  batchDelete,
  batchSet,
  batchUpdate,
  batchUpdateDocs,
  batchUpdateDocsFromPartials,
  store
} from '../../../services/db';
import { callFirebaseFunction } from '../../../services/firebaseFunctions';
import {
  createNewPage,
  pageCollection,
  toPageMetadataDoc
} from '../../../services/page';
import { compareRevisions } from '../../../services/page/revision';
import {
  screenshotV1ToV2,
  toPageScreenshotDoc
} from '../../../services/pageScreenshots';
import { getProductsBySpaceId, toProductDoc } from '../../../services/products';
import { getDefaultDailyEarningsReportSchedule } from '../../../services/schedules/dailyEarnings';
import { getNextRunDate } from '../../../services/schedules/helper';
import { getDefaultLinkCheckSchedule } from '../../../services/schedules/linkCheck';
import { toSecretDoc } from '../../../services/secret';
import { toMoment } from '../../../services/time';
import { CF, FS } from '../../../versions';
import { ButtonContainer } from '../../components/ButtonContainer';
import { SafeExecuteButton } from '../../components/SafeExecuteButton';
import {
  AnchoredSections,
  AnchorLinks,
  ISection
} from '../../layout/AnchoredSection';
import {
  migrateDenormalizations,
  processParallelCapped
} from '../../services/denormalization';
import {
  migrateCollection,
  safeMigrateCollection
} from '../../services/migrations';
import { publishTopic } from '../../services/pubsub';
import { activatesScheduleForEveryone } from '../../services/schedules';
import { getSpaceIds, getSpaces } from '../../services/space';

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const parseUrl = (url: string) => urlParse(url, true);

const toPageKey = (page: Doc<IPageMetadata>) =>
  `${page.data.spaceId}-${page.data.url}`;

const cleanUpPageObjects = async (execute: boolean) => {
  const pages = await pageCollection()
    .get()
    .then((s) => s.docs.map(toPageMetadataDoc));
  const toDelete: string[] = [];
  const toUpdate: Doc<IPageMetadata>[] = [];
  const byUrl = groupBy(pages, toPageKey);
  Object.values(byUrl).forEach((ps) => {
    const first = sortBy(ps, (p) => p.data.createdAt.toMillis())[0];
    const allRevisions = flatten(ps.map((p) => p.data.revisions));
    const allSortedRevisions = sortBy(allRevisions, (r) =>
      r.lastModifiedAt.toMillis()
    );
    const revisions = uniqBy(allSortedRevisions, (p) =>
      p.lastModifiedAt.toMillis()
    );
    ps.forEach((p) => {
      if (p !== first) {
        toDelete.push(p.id);
      }
    });
    if (first.data.revisions.length !== revisions.length) {
      console.log(
        `${first.data.spaceId} ${first.data.url}: Found ${ps.length} document(s).`,
        first.data.revisions.length,
        revisions.length
      );
      first.data.revisions = revisions;
      toUpdate.push(first);
    }
  });

  console.log('toUpdate: ', toUpdate);
  console.log('toDelete: ', toDelete);
  if (execute) {
    await batchSet(FS.pages, toUpdate);
    await batchDelete(FS.pages, toDelete);
  }
};

const seedPageSlugs = async (execute: boolean) => {
  return callFirebaseFunction('migrations-migrate', { execute });
};

const seedPageRevisionData = async (execute: boolean) => {
  const screenshots = await store()
    .collection(FS.pageScreenshots)
    .get()
    .then((s) => s.docs.map(toPageScreenshotDoc));

  console.log(`Screenshot count: ${screenshots.length}`);

  const pages = await pageCollection()
    .get()
    .then((s) => s.docs.map(toPageMetadataDoc));
  const pagesByKey = groupBy(pages, toPageKey);

  console.log(`Pages count: ${pages.length}`);

  const grouped = screenshots.reduce<{
    [spaceId: string]: {
      [url: string]: firebase.firestore.Timestamp[];
    };
  }>((m, s) => {
    const { spaceId, url, pageModifiedAt } = s.data;
    if (pageModifiedAt) {
      const bySpace: { [url: string]: firebase.firestore.Timestamp[] } = (m[
        spaceId
      ] = m[spaceId] || {});
      const byHref: firebase.firestore.Timestamp[] = (bySpace[url] =
        bySpace[url] || []);
      byHref.push(pageModifiedAt);
    }
    return m;
  }, {});

  const spaceIds = Object.keys(grouped);
  const toUpdate: Doc<IPageMetadata>[] = [];
  for (const spaceId of spaceIds) {
    const urls = Object.keys(grouped[spaceId]);
    for (const url of urls) {
      const modificationDates = uniqBy(grouped[spaceId][url], (d) =>
        d.toMillis()
      );

      const pagesForUrl = pagesByKey[`${spaceId}-${url}`] || [];
      if (pagesForUrl.length > 1) {
        console.log('MORE THAN ONE PAGE FOUND', pagesForUrl);
        // find the newest doc and make sure that all revisions we know of
        // are inside of it. Delete all the other documents.
        // Implemented in cleanUpPageObjects above
      }

      const page = pagesForUrl[0] || createNewPage(spaceId, url);

      const newRevisions = modificationDates
        .filter(
          (d) => !page.data.revisions.find((r) => r.lastModifiedAt.isEqual(d))
        )
        .map<IPageRevision>((d) => ({ lastModifiedAt: d }));
      const nextRevisions = [...page.data.revisions, ...newRevisions].sort(
        compareRevisions('asc')
      );
      console.log(
        spaceId,
        url,
        'newRevisions',
        newRevisions,
        'nextRevisions',
        nextRevisions
      );
      if (newRevisions.length) {
        toUpdate.push({
          ...page,
          data: {
            ...page.data,
            revisions: nextRevisions
          }
        });
      }
    }
  }

  console.log('toUpdate: ', toUpdate);

  if (execute) {
    try {
      await batchSet(FS.pages, toUpdate);
    } catch (err) {
      console.log('ERROR', err);
      throw err;
    }
  }
};

const schedulesNextRunDate = async () => {
  const now = moment.utc();
  return migrateCollection<Schedule>(FS.schedules, (schedule) => {
    schedule.nextRun = getNextRunDate(now, schedule.frequency);
    return schedule;
  });
};

const schedulesSetIgnoreSpaceLockout = async () => {
  return migrateCollection<Schedule>(FS.schedules, (schedule) => {
    if (schedule.type === 'TRIAL_REMINDER') {
      schedule.ignoreSpaceLockout = true;
    }
    return schedule;
  });
};

const migrateErrorFieldOnScreenshot = () => {
  // return Promise.resolve();
  return migrateCollection<IPageScreenshot>(FS.pageScreenshots, (s) => {
    s.hasError = s.status === 'ERROR' || s.status === 'NO_SCRIPT';
    return s;
  });
};

const screenshotsV1ToV2 = () =>
  migrateCollection<IPageScreenshot>(FS.pageScreenshots, screenshotV1ToV2);

type DestinationMap = { [productId: string]: string };
const updateProductDestinations = async (execute: boolean) => {
  const spaceIds = await getSpaceIds();
  const destinationMaps = await Promise.all(
    spaceIds.map(async (spaceId) => {
      try {
        const destMap = await callFirebaseFunction<DestinationMap>(
          'products-getDestinationMapForSpace',
          { spaceId }
        );
        return { spaceId, destMap };
      } catch (err) {
        console.log(err);
        return { spaceId, destMap: {} as DestinationMap };
      }
    })
  );

  await Promise.all(
    destinationMaps.map(async ({ spaceId, destMap }) => {
      if (!Object.keys(destMap).length) {
        console.log('No destinations found for ', spaceId);
        return;
      }

      const products = await getProductsBySpaceId(spaceId);
      const toUpdate: Doc<{ destinations: IProductDestination[] }>[] = [];
      products.forEach((p) => {
        const destination = destMap[p.id] || p.data.url;
        const { destinations } = p.data;
        if (!!destinations.find((d) => d.url === destination)) {
          return;
        }
        toUpdate.push({
          id: p.id,
          collection: p.collection,
          data: {
            destinations: [
              ...destinations,
              { url: destination, foundAt: p.data.createdAt }
            ]
          }
        });
      });

      console.log('toUpdate: ', spaceId, toUpdate);
      if (execute && toUpdate.length) {
        await batchUpdate(FS.products, toUpdate);
      }
    })
  );
};

const fixProductDestinationTimestamps = async (execute: boolean) => {
  // Prior to this commit, we stored the migration timestamp as foundAt date.
  // This is wrong - it needs to be aligned with when the product was imported!
  const products = await store()
    .collection(FS.products)
    .get()
    .then((s) => s.docs.map(toProductDoc));
  const toUpdate = products.filter((p) => {
    const firstDestination = p.data.destinations[0];
    if (!firstDestination) {
      // should never happen
      return false;
    }
    try {
      if (firstDestination.foundAt.isEqual(p.data.createdAt)) {
        return false;
      }
      // mutate right away to keep tis efficient
      firstDestination.foundAt = p.data.createdAt;
      return true;
    } catch (err) {
      console.log(err, firstDestination, p);
      return false;
    }
  });

  console.log(`Updating ${toUpdate.length} of ${products.length}`, toUpdate);

  if (execute) {
    await batchSet(FS.products, toUpdate);
  }
};

const testSmartLabelIssues = async (execute: boolean) => {
  // const spaceId = '-tIlujvDG';
  // const spaceId = '3XVPj7l1S';
  const spaceId = '3gik84QmW'; // untoldmorsels
  await publishTopic({
    topic: 'products-updateAllUrlRelatedIssuesInSpace',
    payload: {
      spaceId,
      execute
    }
  });
};

const seedReportingStats = () =>
  callFirebaseFunction<void>('reporting-seedStats');

const fixReportEndDates = async (execute: boolean) => {
  const docs = await store()
    .collection(FS.reports)
    .get()
    .then((s) => {
      const toFix: Doc<IReport>[] = [];
      const reports = s.docs
        .map(toReportDoc)
        .filter((r) => r.data.source.type === 'FILE');
      reports.forEach((r) => {
        // this migration was implemented before we moved
        // to storing sales in cloud storage and is not
        // compatible with this new approach
        if (!r.data.sales) {
          return;
        }
        const sales = decompressType(r.data.sales).map(refreshSaleTimestamps);
        const latest = maxBy(sales, (s) => s.saleDate.toMillis());
        if (!latest) {
          throw new Error('NO LATEST DATE FOUND');
        }
        if (r.data.end.toMillis() !== latest.saleDate.toMillis()) {
          console.log(
            `Change: ${toMoment(r.data.end).toISOString()} > ${toMoment(
              latest.saleDate
            ).toISOString()}`
          );
          r.data.end = latest.saleDate;
          toFix.push(r);
        }
      });

      console.log(`Fixing ${toFix.length} out of ${reports.length}`);
      return toFix;
    });

  if (!execute) {
    console.log('DRY run - abort');
    return;
  }

  await batchSet(FS.reports, docs);
  console.log('Documents updated');
  console.log('Waiting...');
  await wait(20000);
  console.log('Reseeding stats...');
  await seedReportingStats();
};

// eslint-disable-next-line
const reportingMigrate = async (execute: boolean) => {
  return callFirebaseFunction<void>('reporting-_migrate', { execute }).then(
    (r: any) => {
      console.log(r);
      return r;
    }
  );
};

type EarliestDates = {
  [spaceId: string]: {
    [partnerKey: string]: number;
  };
};

const EARLIEST_DATES_DEV: EarliestDates = {
  '4oF26UAIvKcsv6YOxO7gtvrifrn2': {
    awin: 1555445820000,
    cj: 1571144673000,
    skimlinks: 1583052762000
  },
  CG1rijd7J: {
    cj: 1571144673000
  },
  'eg-tgGHoJ': {
    avantlink: 1551579910000,
    cj: 1571144673000,
    rakuten: 1567987200000,
    skimlinks: 1558982743000
  }
};

const EARLIEST_DAYS_PROD: EarliestDates = {
  '-tIlujvDG': {
    avantlink: 1556353886000,
    awin: 1576523760000,
    cj: 1563438800000
  },
  '2cht7CpR9': {
    avantlink: 1593317497000,
    awin: 1568386680000,
    cj: 1543789947000,
    shareasale: 1551391305000
  },
  '2sHEMeAa': {
    cj: 1573219893000,
    shareasale: 1564310604000
  },
  '3XVPj7l1S': {
    awin: 1559971980000,
    cj: 1560778237000,
    pepperjam: 1567160859000,
    shareasale: 1560639504000
  },
  '4WhIOCAzT': {
    avantlink: 1551659462000,
    awin: 1551388320000,
    cj: 1551843107000,
    shareasale: 1552672928000,
    skimlinks: 1548686212000
  },
  '4aiULkiC': {
    avantlink: 1551835771000,
    cj: 1551470654000,
    shareasale: 1552135454000,
    skimlinks: 1551162076000
  },
  '4oF26UAIvKcsv6YOxO7gtvrifrn2': {
    awin: 1555445820000,
    cj: 1558945883000,
    skimlinks: 1583052762000
  },
  '5Pt3t6-Ub': {
    avantlink: 1553507852000,
    awin: 1545278880000,
    cj: 1543306321000,
    shareasale: 1562513009000,
    skimlinks: 1548028799000
  },
  '71TdqV1N6': {
    avantlink: 1551964690000,
    awin: 1552329000000,
    cj: 1543510941000,
    shareasale: 1556829144000
  },
  '8Eu2wVeZ2': {
    awin: 1544376900000,
    cj: 1547712165000
  },
  '93Gjm6plV': {
    awin: 1550195880000,
    cj: 1548921784000,
    pepperjam: 1586339305130
  },
  AZ6xvNo4H: {
    cj: 1546349509000
  },
  BKxAHoZa: {
    skimlinks: 1574500604000
  },
  DrAMl3mRo: {
    awin: 1581420300000,
    cj: 1550669438000
  },
  FG0QLS03Z: {
    avantlink: 1552139912000,
    cj: 1549918818000
  },
  FRmNt2UuH: {
    awin: 1553797740000,
    cj: 1578308427000
  },
  'Fz8aILIT-': {
    cj: 1546653722000
  },
  'G5S-r-JBo': {
    shareasale: 1570309035000,
    skimlinks: 1552257000000
  },
  HRoiyaYKM: {
    awin: 1559466840000,
    cj: 1543498339000,
    skimlinks: 1562563463000
  },
  I1LEhrghC: {
    awin: 1544232120000,
    cj: 1566828133000,
    shareasale: 1564711213000
  },
  L58fL51d: {
    avantlink: 1565648307000,
    skimlinks: 1574605560000
  },
  MTtqZJvSM: {
    shareasale: 1558234496000
  },
  N_Bf9kWS: {
    cj: 1585789421000
  },
  PBE3VQOJ2: {
    cj: 1544967140000
  },
  PHotXfKQ: {
    cj: 1549807435000,
    skimlinks: 1556475600000
  },
  QKOMemp17: {
    skimlinks: 1562183497000
  },
  S6JQtQmSK: {
    cj: 1563354089000
  },
  TBqeZ9v0F: {
    avantlink: 1552313652000,
    cj: 1546954311000,
    skimlinks: 1554795901000
  },
  W6ruy_bJ: {
    cj: 1550685665000
  },
  Xaog9a2q: {
    avantlink: 1552827516000,
    shareasale: 1572975824000,
    skimlinks: 1562616885000
  },
  YVRAyC4oq: {
    shareasale: 1587990106000
  },
  '_FX-96lHZ': {
    awin: 1559524560000,
    cj: 1546884047000,
    shareasale: 1559578800000,
    skimlinks: 1560211200000
  },
  _dtWrWmyT: {
    awin: 1546405920000,
    shareasale: 1567066678000
  },
  aGR53eu6Z: {
    awin: 1565111520000,
    cj: 1575561707000
  },
  bZ5F7ml6s: {
    skimlinks: 1581033600000
  },
  cf2YNa0VQ: {
    awin: 1555665360000,
    cj: 1555677205000
  },
  eTxwk_Ht9: {
    cj: 1576972843000
  },
  gDTBDJJJ: {
    awin: 1574857800000,
    cj: 1552653165000
  },
  jTZtXKdY2: {
    cj: 1559703609000,
    shareasale: 1580595848000,
    skimlinks: 1556620654000
  },
  lisGSGe1f: {
    awin: 1554125400000,
    cj: 1569414631000,
    skimlinks: 1560932399000
  },
  oYu7nTFzV: {
    cj: 1566741755000
  },
  sqYmYSEJ: {
    cj: 1548425007000
  },
  uYqoDxsoj: {
    cj: 1549375413000
  },
  v7OYzvqyE: {
    awin: 1556064060000,
    cj: 1557828498000,
    shareasale: 1574844987000
  },
  x4zDyTsQJ: {
    cj: 1588257034000,
    shareasale: 1590019434000
  },
  yqxkn2Kxu: {
    awin: 1563981360000,
    skimlinks: 1562845620000
  }
};

const USE_PROD = false;

const triggerAllApiReportingHandlers = async (execute: boolean) => {
  const end = Date.now();
  const jobs: IReportingPreviewParams[] = [];

  const secrets = await store()
    .collection(FS.secretsPublic)
    .get()
    .then((s) => s.docs.map(toSecretDoc));
  const secretsBySpaceByName = secrets.reduce<{
    [spaceId: string]: {
      [handlerName: string]: string[];
    };
  }>((m, s) => {
    const { spaceId, name, instanceId } = s.data;
    const bySpace = (m[spaceId] = m[spaceId] || {});
    const byHandlerName = (bySpace[name] = bySpace[name] || []);
    byHandlerName.push(instanceId);
    return m;
  }, {});

  Object.entries(USE_PROD ? EARLIEST_DAYS_PROD : EARLIEST_DATES_DEV).forEach(
    ([spaceId, d]) => {
      Object.entries(d).forEach(([partnerKey, start]) => {
        const handler = API_REPORT_HANDLERS.find(
          (h) => h.partnerKey === partnerKey
        );
        if (!handler) {
          return;
        }

        const instanceIds =
          secretsBySpaceByName[spaceId]?.[handler.configName] || [];
        instanceIds.forEach((integrationId) => {
          jobs.push({
            spaceId,
            handler: handler.configName,
            integrationId,
            start,
            end
          });
        });
      });
    }
  );

  console.log(
    'JOBS',
    jobs.map((j) => [j, new Date(j.start)])
  );

  if (execute) {
    await Promise.all(
      jobs.map((params) =>
        callFirebaseFunction(CF.reporting.triggerApiHandler, params).then(() =>
          console.log('Job done', params)
        )
      )
    );
  }
};

const backportSecretInstanceIds = async (execute: boolean) => {
  const docs = await store()
    .collection(FS.secretsPrivate)
    .get()
    .then((s) => s.docs.map(toSecretDoc));
  const partials = docs.map<Doc<Pick<ISecret, 'instanceId'>>>((d) => ({
    ...d,
    data: { instanceId: d.data.instanceId }
  }));
  console.log(docs, partials);
  if (execute) {
    await batchUpdateDocsFromPartials(partials);
  }
};

const relinkSales = async (execute: boolean) => {
  const spaceIds = await getSpaceIds();
  return processParallelCapped(
    spaceIds.map((spaceId) => async () => {
      const result = await callFirebaseFunction<Doc<ITrackedSale>[]>(
        CF.reporting.relinkSales,
        {
          spaceId,
          execute
        }
      );
      console.log(result);
    }),
    15
  );
};

const retrofitDeviceAndPageUrlData = async (execute: boolean) => {
  const docs = await store()
    .collection(FS.sales)
    .orderBy('click')
    .startAfter(null)
    .get()
    .then((s) => s.docs.map(toTrackedSaleDoc));

  console.log(docs);

  const partials = docs.map((d) => ({
    ...d,
    data: pick(d.data, ['device', 'pageUrl'])
  }));

  if (execute) {
    await batchUpdate(FS.sales, partials);
  }
};

const migrateAutoLabelConfigs = async (execute: boolean) => {
  const configs = await store()
    .collection(FS.autoLabelConfigs)
    .get()
    // toAutoLabelConfigDoc performs the transformation
    .then((s) => s.docs.map(toAutoLabelConfigDoc));
  console.log(configs);
  if (execute) {
    await batchSet(FS.autoLabelConfigs, configs);
  }
};

export const PageMigrations = () => {
  const SECTIONS: ISection[] = [
    {
      anchor: 'denormalization',
      label: 'Denormalization',
      el: (
        <Section>
          <CanvasBar>Denormalization</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={migrateDenormalizations}
                >
                  Migrate all denormalizations
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'pages',
      label: 'Pages',
      el: (
        <Section>
          <CanvasBar>Pages</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={() => migrateErrorFieldOnScreenshot()}
                  pending="Migrating..."
                >
                  Screenshots hasError seed
                </ButtonWithPromise>

                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={() => screenshotsV1ToV2()}
                  pending="Migrating..."
                >
                  Screenshots V1 to V2
                </ButtonWithPromise>

                <SafeExecuteButton
                  variant="contained"
                  color="primary"
                  onClick={seedPageRevisionData}
                >
                  Seed Page revision data
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={cleanUpPageObjects}
                >
                  Clean up duplicate page objects and revisions
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={seedPageSlugs}
                >
                  Seed Page Slugs
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'products',
      label: 'products',
      el: (
        <Section>
          <CanvasBar>Products</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={updateProductDestinations}
                >
                  Update destinations
                </SafeExecuteButton>
                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={fixProductDestinationTimestamps}
                >
                  Fix destination timestamp
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={testSmartLabelIssues}
                >
                  Test smart label issues
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'reporting',
      label: 'Reporting',
      el: (
        <Section>
          <CanvasBar>Reporting</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <ButtonWithPromise
                  variant="contained"
                  color="secondary"
                  pending="Seeding..."
                  onClick={seedReportingStats}
                >
                  Seed all stats
                </ButtonWithPromise>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={fixReportEndDates}
                >
                  Fix report end dates
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={backportSecretInstanceIds}
                >
                  Backport instanceIds on secrets
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={reportingMigrate}
                >
                  Trigger domain migration through CF
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={triggerAllApiReportingHandlers}
                >
                  Trigger all API handlers
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'sales',
      label: 'Sales',
      el: (
        <Section>
          <CanvasBar>Sales</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={relinkSales}
                >
                  Relink sales
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="primary"
                  onClick={retrofitDeviceAndPageUrlData}
                >
                  Retrofit device and pageUrl data
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'schedules',
      label: 'Schedules',
      el: (
        <Section>
          <CanvasBar>Schedules</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={() => schedulesNextRunDate()}
                  pending="Setting..."
                >
                  Set nextRun date
                </ButtonWithPromise>

                <ButtonWithPromise
                  variant="contained"
                  color="primary"
                  onClick={() => schedulesSetIgnoreSpaceLockout()}
                  pending="Setting..."
                >
                  Set ignoreSpaceLockout
                </ButtonWithPromise>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={(execute) =>
                    activatesScheduleForEveryone(
                      'DAILY_EARNINGS_REPORT',
                      getDefaultDailyEarningsReportSchedule,
                      execute
                    )
                  }
                >
                  Activate DAILY_EARNINGS_REPORT for everyone
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={(execute) =>
                    activatesScheduleForEveryone(
                      'LINK_CHECK',
                      getDefaultLinkCheckSchedule,
                      execute
                    )
                  }
                >
                  Activate LINK_CHECK for everyone
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'space',
      label: 'Space',
      el: (
        <Section>
          <CanvasBar>Space</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <ButtonContainer>
                  <ButtonWithPromise
                    variant="contained"
                    color="secondary"
                    pending="Refreshing..."
                    onClick={() =>
                      callFirebaseFunction(
                        'reporting-syncRevenueToExternalPlatforms'
                      )
                    }
                  >
                    Sync revenue to external platforms
                  </ButtonWithPromise>
                </ButtonContainer>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={(execute) =>
                    safeMigrateCollection<ISpace>(
                      FS.spaces,
                      (space) => {
                        if (space.affiliateId) {
                          return;
                        }
                        space.affiliateId = shortid();
                        return space;
                      },
                      execute,
                      300
                    )
                  }
                >
                  Seed affiliate ids
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={async (execute) => {
                    const spaces = await getSpaces();
                    const toUpdate: Doc<{
                      onboarding: ISpace['onboarding'];
                    }>[] = [];
                    await Promise.all(
                      spaces.map(async (space) => {
                        const hasDomains = !!space.data.domains.length;
                        if (!hasDomains) {
                          return;
                        }
                        const hasProducts = store()
                          .collection(FS.products)
                          .where('spaceId', '==', space.id)
                          .limit(1)
                          .get()
                          .then((s) => !s.empty);

                        const finishedAt = space.data.createdAt;
                        const finishedBy = space.data.createdBy;

                        const item: ISpaceOnboardingItem = {
                          finishedAt,
                          finishedBy
                        };

                        let needsUpdate = false;

                        if (!space.data.onboarding.verifiedWebsite) {
                          space.data.onboarding.verifiedWebsite = item;
                          needsUpdate = true;
                        }
                        if (
                          hasProducts &&
                          !space.data.onboarding.importedProducts
                        ) {
                          space.data.onboarding.importedProducts = item;
                          needsUpdate = true;
                        }
                        if (needsUpdate) {
                          toUpdate.push({
                            ...space,
                            data: {
                              onboarding: space.data.onboarding
                            }
                          });
                        }
                      })
                    );

                    console.log(
                      `Total spaces ${spaces.length} - toUpdate: `,
                      toUpdate
                    );
                    if (execute) {
                      await batchUpdateDocsFromPartials(toUpdate);
                    }
                  }}
                >
                  Seed onboarding stats
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="primary"
                  onClick={async (execute) => {
                    const spaces = await getSpaces();
                    const withUpdates = spaces.map((s) => {
                      return {
                        ...s,
                        data: {
                          ...s.data,
                          config: {
                            ...s.data.config,
                            currency: s.data.config.currency || 'USD'
                          }
                        }
                      };
                    });
                    console.log(
                      withUpdates,
                      withUpdates.map((s) => s.data.config.currency)
                    );
                    if (execute) {
                      await batchUpdateDocs(withUpdates, (s) => ({
                        config: s.config
                      }));
                    }
                  }}
                >
                  Migrate currency
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    },
    {
      anchor: 'trackingConfig',
      label: 'Tracking Config',
      el: (
        <Section>
          <CanvasBar>Tracking Config</CanvasBar>
          <Card>
            <CardContent>
              <ButtonContainer>
                <ButtonWithPromise
                  variant="contained"
                  color="secondary"
                  pending="Refreshing..."
                  onClick={() =>
                    callFirebaseFunction('trackingConfig-refreshAll')
                  }
                >
                  Refresh all
                </ButtonWithPromise>
              </ButtonContainer>

              <ButtonContainer>
                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={async (execute) => {
                    const spaces = await getSpaces();

                    const n = Date.now();
                    return Promise.all(
                      spaces.map((s) => {
                        if (isLockedOut(s.data, n)) {
                          return null;
                        }
                        if (execute) {
                          return callFirebaseFunction(
                            'trackingConfig_v2-refreshTrackingConfig',
                            { spaceId: s.id }
                          );
                        } else {
                          console.log(`Would start ${s.id}`);
                        }
                        return null;
                      })
                    );
                  }}
                >
                  Refresh all (v2 - Redis)
                </SafeExecuteButton>

                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={async (execute) => {
                    if (!execute) {
                      console.log('No preview available');
                    }
                    await callFirebaseFunction(
                      'trackingConfig_v2-refreshAllApiTokenVerificationData'
                    );
                  }}
                >
                  Refresh all api token verification data
                </SafeExecuteButton>
              </ButtonContainer>

              <ButtonContainer>
                <SafeExecuteButton
                  variant="contained"
                  color="secondary"
                  onClick={migrateAutoLabelConfigs}
                >
                  Migrate auto-label configs
                </SafeExecuteButton>
              </ButtonContainer>
            </CardContent>
          </Card>
        </Section>
      )
    }
  ];
  return (
    <>
      <Page>
        <AnchorLinks sections={SECTIONS} offset={100} />
        <AnchoredSections sections={SECTIONS} />
        <Section>
          <ButtonContainer>
            <Button
              onClick={async () => {
                const spaceIds = await getSpaceIds();

                await Promise.all(
                  spaceIds
                    // for testing
                    .filter((sId) => sId === '4oF26UAIvKcsv6YOxO7gtvrifrn2')
                    .filter((sId) => sId !== SPACE_ID)
                    .map((spaceId) =>
                      publishTopic({
                        topic:
                          'SCRAPING-removeAllProductScansOlderThanNDaysInSpace',
                        payload: {
                          spaceId,
                          maxAgeInDaysAllowed: 30,
                          execute: false
                        }
                      })
                    )
                );
              }}
            >
              Cleanup scans
            </Button>
          </ButtonContainer>
        </Section>
      </Page>
    </>
  );
};
