import { CollectionDefinition } from '.';
import { AdditionActionsMenuOption } from '../../../components/AdditionalActionsMenu';
import { Doc } from '../../../domainTypes/document';
import { Type } from '../../../domainTypes/schedule';
import { FS } from '../../../versions';
import { toFirestoreConsole } from '../../services/firebase';

const getFieldsForCollection = (collection: keyof typeof FS) => {
  const spaceId = {
    name: 'spaceId',
    values: ['WOO2CVcgO', '4oF26UAIvKcsv6YOxO7gtvrifrn2']
  };
  switch (collection) {
    case 'pages':
      return [spaceId, { name: 'url', values: [] }];
    case 'schedules':
      return [
        spaceId,
        {
          name: 'type',
          values: [
            'PRODUCT_SCAN',
            'PRODUCT_SCAN_REMOVAL',
            'REPORT',
            'TRIAL_REMINDER',
            'SALES_API_FETCH',
            'DAILY_EARNINGS_REPORT',
            'DAILY_EARNINGS_REPORT2',
            'DATA_EXPORT',
            'LINK_CHECK'
          ] as Type[]
        }
      ];
    default:
      return [spaceId];
  }
};

const getActionsForCollection = (
  collection: keyof typeof FS
): ((d: Doc<any>) => AdditionActionsMenuOption[]) => {
  const linkToFsConsole = (d: Doc<any>): AdditionActionsMenuOption => ({
    key: 'openInFsConsole',
    label: 'Open in FS console',
    externalHref: toFirestoreConsole(d.collection, d.id)
  });
  switch (collection) {
    default:
      return (d: Doc<any>) => [linkToFsConsole(d)];
  }
};

export const FIRESTORE_INSPECTOR_COLLECTIONS: CollectionDefinition[] = Object.entries(
  FS
).map(([key, value]) => ({
  name: value,
  fields: getFieldsForCollection(key as keyof typeof FS),
  actions: getActionsForCollection(key as keyof typeof FS)
}));
